import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

type AuthRequiredProps = {
  authRequiredModalOpen: boolean;
  setAuthRequiredModalOpen: (state: boolean) => void;
};
function AuthRequiredModal({
  authRequiredModalOpen,
  setAuthRequiredModalOpen,
}: AuthRequiredProps) {
  return (
    <Dialog open={authRequiredModalOpen} fullWidth>
      <DialogTitle>Please Log In</DialogTitle>
      <DialogContent>
        <Button
          variant="contained"
          onClick={() => setAuthRequiredModalOpen(false)}
          href="/login"
        >
          Login
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default AuthRequiredModal;
