import TextField from "@mui/material/TextField";
import { IEmployee } from "models/Employee";
import React from "react";

interface TextFieldProps {
  label: string;
  field: string;
  employee: IEmployee;
  handleChange?: (field: any, value: String) => void;
  disabled?: boolean;
}

const TextInput = ({
  label,
  field,
  employee,
  handleChange: updateEmployee,
  disabled,
}: TextFieldProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (updateEmployee !== undefined) updateEmployee(field, e.target.value);
  };
  return (
    <TextField
      variant="outlined"
      label={label}
      defaultValue={employee[field]}
      fullWidth
      onChange={handleOnChange}
      disabled={disabled}
      sx={{ m: 1 }}
    />
  );
};

export default TextInput;
