import axios from "axios";
import { IEmployee } from "models/Employee";
import { IResponse } from "models/Response";

export default class EmployeeService {
  private API_URL = process.env.REACT_APP_API_URL as string;
  private accessToken: string;

  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }

  async getAllEmployees(): Promise<IResponse> {
    return await axios
      .get(this.API_URL, {
        headers: { Authorization: `Bearer ${this.accessToken}` },
        timeout: 5000,
      })
      .then((response) => {
        const employees = response.data as IEmployee[];
        return {
          success: true,
          message: null,
          data: employees,
        };
      })
      .catch((err) => {
        return {
          success: false,
          message: err.response?.data?.message || err.message || null,
          data: null,
        };
      });
  }

  async updateEmployee(employee: IEmployee): Promise<IResponse> {
    return await axios
      .put(this.API_URL, employee, {
        headers: { Authorization: `Bearer ${this.accessToken}` },
        timeout: 5000,
      })
      .then((response) => {
        return {
          success: true,
          message: null,
          data: response.data || null,
        };
      })
      .catch((err) => {
        return {
          success: false,
          message: err.response?.data?.message || err.message || null,
          data: null,
        };
      });
  }
}
