import { Button, TableBody, TableRow } from "@mui/material";
import UpdateModal from "components/UpdateModal/UpdateModal";
import { ApiContext, ApiReducer, AuthContext } from "context";
import { IEmployee } from "models/Employee";
import { useContext, useState } from "react";
import EmployeeTableCell from "./EmployeeTableCell";

const tableRowStyle = {
  "&:nth-of-type(odd)": {
    bgcolor: "#E0E0E0",
  },
};

function EmployeeTableBody() {
  const [employeeToUpdate, setEmployeeToUpdate] = useState<IEmployee | null>(
    null
  );
  const { state, dispatch, apiRequests } = useContext(ApiContext.ApiContext);
  const { isAdmin } = useContext(AuthContext.AuthContext);

  const handleUpdateButtonClick = (employee: IEmployee) => {
    setEmployeeToUpdate(employee);
    dispatch({ type: ApiReducer.ACTIONS.OPEN_MODAL, employee: employee });
  };

  const handleSubmit = () => {
    apiRequests.updateEmployee(employeeToUpdate as IEmployee);
  };

  const dateToString = (date: string) => {
    return new Date(date).toLocaleDateString();
  };

  return (
    <>
      <TableBody>
        {state.allEmployees.map((employee: IEmployee, index: number) => (
          <TableRow key={index} sx={tableRowStyle}>
            <EmployeeTableCell>{employee.firstName}</EmployeeTableCell>
            <EmployeeTableCell>{employee.surname}</EmployeeTableCell>
            <EmployeeTableCell>
              {dateToString(employee.dateOfBirth)}
            </EmployeeTableCell>
            <EmployeeTableCell>{employee.phone}</EmployeeTableCell>
            {isAdmin && (
              <EmployeeTableCell>
                <Button
                  variant="contained"
                  onClick={() => handleUpdateButtonClick(employee)}
                >
                  Update
                </Button>
              </EmployeeTableCell>
            )}
          </TableRow>
        ))}

        {state.openModal && (
          <UpdateModal
            open={state.openModal}
            handleClose={() =>
              dispatch({ type: ApiReducer.ACTIONS.CLOSE_MODAL })
            }
            employee={employeeToUpdate as IEmployee}
            setEmployee={setEmployeeToUpdate}
            submit={handleSubmit}
          />
        )}
      </TableBody>
    </>
  );
}

export default EmployeeTableBody;
