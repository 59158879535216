import {
  CustomUserClaims,
  UserClaims,
} from "@okta/okta-auth-js/lib/types/UserClaims";
import { useOktaAuth } from "@okta/okta-react";
import "App.css";
import { createContext, useEffect, useState } from "react";

// Add roles claim to UserClaims --> does not have this claim by default
type UserClaimsWithGroup = UserClaims<CustomUserClaims> & { roles: string[] };

type AuthContextType = {
  user: UserClaimsWithGroup | null;
  signOut: () => void;
  isAdmin: boolean;
  accessToken: string;
};
const AuthContext = createContext<AuthContextType>({
  user: null,
  signOut: () => {},
  isAdmin: false,
  accessToken: "",
});

const groups = {
  USER: process.env.REACT_APP_USER_GROUP_ID || "",
  ADMIN: process.env.REACT_APP_ADMIN_GROUP_ID || "",
};

type ProviderProps = { children: React.ReactNode };
function AuthProvider({ children }: ProviderProps) {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaimsWithGroup | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>("");

  const signOut = () => oktaAuth.signOut();

  const isUserAdmin = (userGroups: string[]): boolean => {
    return userGroups.includes(groups.ADMIN);
  };

  const getAccessToken = (): string => {
    return oktaAuth.getAccessToken() || "";
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          const newUserInfo = info as UserClaimsWithGroup;
          setUserInfo(newUserInfo);
          setIsAdmin(isUserAdmin(newUserInfo?.roles || {}));
          setAccessToken(getAccessToken);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]);

  return (
    <AuthContext.Provider
      value={{
        user: userInfo,
        signOut: signOut,
        isAdmin: isAdmin,
        accessToken: accessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default { AuthProvider, AuthContext };
