import TableCell from "@mui/material/TableCell";
import React, { ReactElement } from "react";

type Props = {
  children: ReactElement<any, any> | String | null;
};

const EmployeeTableCell = ({ children }: Props): JSX.Element => (
  <TableCell align="center">{children}</TableCell>
);

export default EmployeeTableCell;
