import { createTheme, ThemeProvider } from "@mui/material/styles";
import Catamaran from "assets/fonts/catamaran.regular.ttf";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// Use catamaran by default
const theme = createTheme({
  typography: {
    fontFamily: "Catamaran Regular",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Catamaran Regular";
          src: url(${Catamaran}) format("truetype");
        }
      `,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
