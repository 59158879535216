import { Button, Skeleton, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { ApiContext, AuthContext } from "context";
import { useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import EmployeeTableBody from "./EmployeeTableBody";
import EmployeeTableHead from "./EmployeeTableHead";

function EmployeeTableComp() {
  const { state, apiRequests } = useContext(ApiContext.ApiContext);
  const { accessToken } = useContext(AuthContext.AuthContext);

  // On change to access token
  useEffect(() => {
    if (accessToken !== "") apiRequests.getAllEmployees();
  }, [accessToken]);

  return (
    <>
      {state.loading ? (
        <Skeleton
          sx={{ height: "50vh", width: "90%", maxWidth: 1200, margin: "auto" }}
        />
      ) : state.error ? (
        <>
          <Skeleton
            sx={{
              height: "1000px",
              width: "90%",
              maxWidth: 1200,
              margin: "auto",
              mt: -20,
            }}
          />
          <Button
            onClick={() => apiRequests.getAllEmployees()}
            color="error"
            size="large"
            variant="contained"
            sx={{ mt: -30 }}
          >
            <Typography variant="h6">Reload</Typography>
          </Button>
        </>
      ) : (
        <TableContainer sx={{ marginTop: "10px", mb: "50px" }}>
          <Table sx={{ width: "90%", maxWidth: 1200, margin: "auto" }}>
            <EmployeeTableHead />
            <EmployeeTableBody />
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default function EmployeeTable() {
  const { accessToken } = useContext(AuthContext.AuthContext);
  return (
    <ApiContext.TableProvider accessToken={accessToken}>
      <EmployeeTableComp />
    </ApiContext.TableProvider>
  );
}
