import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CatamaranLight from "assets/fonts/catamaran.extralight.ttf";

const containerStyle = {
  bgcolor: "#5876aa",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

function Titlebar() {
  return (
    <Box sx={{ maxWidth: "100vw", bgcolor: "#5876aa" }}>
      <Container disableGutters maxWidth="lg" sx={containerStyle}>
        <img
          width="300px"
          height="auto"
          src={require("assets/images/CorporateLogotype+Tagline_Right_White.png")}
          alt="NTT DATA"
        />
        <Typography variant="h5" fontFamily={CatamaranLight} color="white">
          Bank Employees Address Book
        </Typography>
      </Container>
    </Box>
  );
}

export default Titlebar;
