import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
interface DateProps {
  label: string;
  field: string;
  date: string;
  handleChange: (field: any, value: String) => void;
  disabled?: boolean;
}

function DateInput({ label, field, date, handleChange }: DateProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label={label}
        inputFormat="MM/DD/YYYY"
        value={dayjs(date)}
        onChange={(e) => {
          handleChange(field, e?.format("MM/DD/YYYY") || date);
        }}
        renderInput={(params) => (
          <TextField {...params} sx={{ m: 1 }} fullWidth />
        )}
      />
    </LocalizationProvider>
  );
}

export { DateInput };
