import { IEmployee } from "models/Employee";
import { toast } from "react-toastify";

export class Reducer {
  public static apiReducer(
    state: Reducer.ApiState,
    action: Partial<Reducer.Action>
  ): Reducer.ApiState {
    switch (action.type) {
      case Reducer.ACTIONS.CALL_API: {
        toast.dismiss(Reducer.ACTIONS.ERROR);
        toast.info(action.toastMessage || "Loading Users", {
          position: toast.POSITION.TOP_CENTER,
          toastId: Reducer.ACTIONS.CALL_API,
        });
        return {
          ...state,
          loading: true,
          openModal: false,
          error: null,
        };
      }
      case Reducer.ACTIONS.SUCCESS: {
        toast.update(Reducer.ACTIONS.CALL_API, {
          render: action.toastMessage || "Records Reloaded",
          type: "success",
          autoClose: 1000,
        });

        return {
          ...state,
          loading: false,
          allEmployees: action.allEmployees as IEmployee[],
          openModal: false,
          error: null,
        };
      }
      case Reducer.ACTIONS.ERROR: {
        const toastMessage =
          action.toastMessage ||
          (action.error as string) ||
          "Error, try again later";

        // Dismiss for more noticeable animation
        toast.dismiss(Reducer.ACTIONS.CALL_API);
        toast.error(toastMessage, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          toastId: Reducer.ACTIONS.ERROR,
        });

        return {
          ...state,
          loading: false,
          error: action.error as string,
          openModal: false,
        };
      }
      case Reducer.ACTIONS.OPEN_MODAL: {
        return {
          ...state,
          openModal: true,
        };
      }
      case Reducer.ACTIONS.CLOSE_MODAL: {
        return {
          ...state,
          openModal: false,
        };
      }
      default:
        return { ...state };
    }
  }
}

export namespace Reducer {
  export enum ACTIONS {
    CALL_API,
    SUCCESS,
    ERROR,
    OPEN_MODAL,
    CLOSE_MODAL,
  }
  export type Action = {
    type: ACTIONS;
    allEmployees: IEmployee[];
    employee: IEmployee;
    error: string;
    toastMessage?: string;
  };

  export interface ApiState {
    allEmployees: IEmployee[];
    loading: boolean;
    error: string | null;
    openModal: boolean;
  }
}
