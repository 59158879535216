import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AuthContext } from "context";
import React, { useContext } from "react";
import EmployeeTableCell from "./EmployeeTableCell";

const tableHead = ["First Name", "Last Name", "DOB", "Phone"];

function EmployeeTableHead() {
  const { isAdmin } = useContext(AuthContext.AuthContext);
  return (
    <TableHead>
      <TableRow
        sx={{
          th: {
            color: "white",
            backgroundColor: "black",
          },
        }}
      >
        {tableHead.map((val: String) => (
          <EmployeeTableCell key={val as React.Key}>{val}</EmployeeTableCell>
        ))}
        {isAdmin && <EmployeeTableCell>Update</EmployeeTableCell>}
      </TableRow>
    </TableHead>
  );
}

export default EmployeeTableHead;
