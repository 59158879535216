import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { AuthContext } from "context";
import { useContext } from "react";

function Navbar(): JSX.Element {
  const { user, signOut } = useContext(AuthContext.AuthContext);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ bgcolor: "black" }}>
          <Typography component="div" sx={{ flexGrow: 1 }}>
            Good Evening {user?.name}
          </Typography>
          <Button color="primary" variant="contained" onClick={signOut}>
            Sign Out
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;
