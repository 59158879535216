import EmployeeService from "api/ApiService";
import { ApiReducer } from "context";
import { IEmployee } from "models/Employee";
import React, { createContext, useReducer } from "react";
import { Reducer } from "./ApiReducer";

const initial: Reducer.ApiState = {
  allEmployees: [],
  loading: false,
  error: null,
  openModal: false,
};

type ApiRequests = {
  getAllEmployees: (toastMessage?: string) => void;
  updateEmployee: (employeeToUpdate: IEmployee) => void;
};
type ApiContext = {
  state: Reducer.ApiState;
  dispatch: React.Dispatch<any>;
  apiRequests: ApiRequests;
};
const ApiContext = createContext<ApiContext>({} as ApiContext);

type ProviderProps = { accessToken: string; children: React.ReactNode };
const TableProvider = ({ accessToken, children }: ProviderProps) => {
  const [state, dispatch] = useReducer(Reducer.apiReducer, initial);
  const service: EmployeeService = new EmployeeService(accessToken);

  const getAllEmployees = (toastMessage?: string) => {
    if (!state.loading) dispatch({ type: ApiReducer.ACTIONS.CALL_API });
    service.getAllEmployees().then((res) => {
      if (res.success) {
        dispatch({
          type: ApiReducer.ACTIONS.SUCCESS,
          allEmployees: res.data as IEmployee[],
          toastMessage,
        });
      } else {
        dispatch({
          type: ApiReducer.ACTIONS.ERROR,
          error: res?.message ?? "Could not get records",
        });
      }
    });
  };

  const updateEmployee = (employeeToUpdate: IEmployee) => {
    dispatch({ type: ApiReducer.ACTIONS.CALL_API });
    if (employeeToUpdate != null) {
      employeeToUpdate.dateOfBirth = new Date(
        employeeToUpdate.dateOfBirth
      ).toISOString();
      service.updateEmployee(employeeToUpdate).then((resp) => {
        if (resp.success) {
          getAllEmployees("Record Saved");
        } else {
          dispatch({
            type: ApiReducer.ACTIONS.ERROR,
            error: resp.message ?? "Could not Update Employee",
          });
        }
      });
    }
  };

  const apiRequests: ApiRequests = { getAllEmployees, updateEmployee };

  return (
    <ApiContext.Provider value={{ state, dispatch, apiRequests }}>
      {children}
    </ApiContext.Provider>
  );
};

export default { TableProvider, ApiContext };
