import { CssBaseline } from "@mui/material";
import "App.css";
import { EmployeeTable, Navbar, Titlebar } from "components";
import { AuthContext } from "context";
import { ToastContainer } from "react-toastify";

function EmployeePage() {
  return (
    <div className="App2">
      <CssBaseline />
      <AuthContext.AuthProvider>
        <Navbar />
        <Titlebar />
        <EmployeeTable />
      </AuthContext.AuthProvider>
      <ToastContainer />
    </div>
  );
}

export default EmployeePage;
