import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { IEmployee } from "models/Employee";
import { DateInput } from "./DateInput";
import TextInput from "./TextInput";

interface Props {
  open: boolean;
  handleClose: (value: React.SetStateAction<boolean>) => void;
  employee: IEmployee;
  setEmployee: React.Dispatch<React.SetStateAction<IEmployee | null>>;
  submit: () => void;
}

function UpdateModal({
  open,
  handleClose,
  employee,
  setEmployee,
  submit,
}: Props) {
  const handleUpdateEmployee = (field: any, value: String) => {
    setEmployee({ ...employee, [field]: value });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Update Employee</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="off" autoCorrect="off">
          <Grid container direction="row" spacing={1}>
            <Grid item xs={4}>
              <TextInput
                label="Title"
                employee={employee}
                disabled
                field="personTitle"
              />
              <TextInput
                label="Gender"
                employee={employee}
                disabled
                field="gender"
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label="First Name"
                employee={employee}
                handleChange={handleUpdateEmployee}
                field="firstName"
              />
              <DateInput
                label="Date of Birth"
                field="dateOfBirth"
                date={employee.dateOfBirth}
                handleChange={handleUpdateEmployee}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label="Last Name"
                employee={employee}
                handleChange={handleUpdateEmployee}
                field="surname"
              />
              <TextInput
                label="Phone"
                employee={employee}
                field="phone"
                handleChange={handleUpdateEmployee}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                size="large"
                variant="contained"
                onClick={submit}
                sx={{ m: 1 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
export default UpdateModal;
