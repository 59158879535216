import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import "App.css";
import { Auth, NotFound } from "components";
import config from "config";
import EmployeePage from "pages/EmployeePage";
import { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const history = useHistory();
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false);

  // Okta login functions from documentation
  const triggerLogin = () => {
    history.push("/login");
  };
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || "", window.location.origin));
  };
  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      triggerLogin();
    } else {
      setAuthRequiredModalOpen(true);
    }
  };
  const onAuthResume = async () => {
    history.push("/login");
  };

  return (
    <div className="App">
      <header className="App-header">
        <Security // OKTA Provider
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
          restoreOriginalUri={restoreOriginalUri}
        >
          <Switch>
            <Route path="/" exact>
              {/* Reroutes to secured route */}
              <Redirect to="/employees" />
            </Route>
            <SecureRoute path="/employees" component={EmployeePage} />
            <Route path="/login" render={() => <Auth.Login />} />
            <Route
              path="/login/callback"
              render={(props) => (
                <LoginCallback {...props} onAuthResume={onAuthResume} />
              )}
            />
            <Route component={NotFound} />
          </Switch>
          <Auth.AuthRequiredModal
            authRequiredModalOpen={authRequiredModalOpen}
            setAuthRequiredModalOpen={setAuthRequiredModalOpen}
          />
        </Security>
      </header>
    </div>
  );
};

export default App;
